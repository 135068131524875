import { Injectable } from '@angular/core'

@Injectable()
export class RsLoggerService {
  active = true

  private log(data: any) {
    if (this.active) {
      console.log(...data)
    }
  }

  info(...data: any[]) {
    this.log(data)
  }

  debug() {}
  warn() {}
  error() {}
  trace() {}
  success() {}
  table() {}
}
