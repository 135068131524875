import { configureSentry, logInfo } from '@revspringinc/shared';

import { environment } from './environments/environment';
import { VERSION } from './environments/version';

logInfo('💎 version 💎', VERSION);

configureSentry({
    dsn: 'https://8933b003087743efba138754588b8c35@o367895.ingest.sentry.io/5591693',
    environment: environment.environmentName,
    version: VERSION,
});

import('./bootstrap').catch((err) => console.error(err));

// force rebuild
