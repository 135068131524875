import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { EventAction, EventType, SseEvent } from '../models/sse-event';
import { logEvent, logInfo } from '../utils';

@Injectable({
    providedIn: 'root',
})
export class SseService {
    protected url: string;

    private readonly eventDispatcher: BehaviorSubject<SseEvent<any>>;
    private eventSource: EventSource;
    private listenedEvents: { eventType: string; eventAction: string }[] = [];
    private retryFrequencySeconds = 1;

    constructor(private readonly http: HttpClient, private readonly zone: NgZone) {
        this.eventDispatcher = new BehaviorSubject<SseEvent<any>>(null);

        logInfo('SSE Service', 'SSE Event Service created');
    }

    public listen(sessionId, eventType, eventAction) {
        try {
            if (!this.isSupported()) {
                logInfo('SSE Service', 'SSE is not supported');

                return;
            }

            const matchedListenedEvent = this.listenedEvents.find(
                (x) => x.eventAction === eventAction && x.eventType === eventType,
            );

            if (!matchedListenedEvent) {
                this.listenedEvents.push({ eventAction, eventType });
            }

            if (
                this.eventSource?.readyState !== EventSource.OPEN &&
                this.eventSource?.readyState !== EventSource.CONNECTING
            ) {
                this.setEventSource(sessionId);
            }
        } catch (e) {
            throw new Error(e.message ?? 'SSE Service - failed to listen to eventSource');
        }
    }

    public closeServerSentEvent(sessionId: string | number) {
        try {
            if (!this.isSupported()) {
                logInfo('SSE Service', 'SSE is not supported');

                return;
            }

            logInfo('SSE Service', `closing server sent events for ${sessionId}`);
            this.eventSource?.close();
            this.unsubscribe(sessionId.toString())
                .pipe(
                    first(),
                    catchError(() => of()),
                )
                .subscribe();
        } catch (e) {
            throw new Error(e.message ?? 'SSE Service - failed to close eventSource');
        }
    }

    public unsubscribe(sessionId: string): Observable<unknown> {
        return this.http.get<unknown>(`${this.url}/unsubscribe/${sessionId}`);
    }

    public on(eventType: EventType, eventAction: EventAction): Observable<SseEvent<any>> {
        return this.eventDispatcher.pipe(
            filter((event) => {
                return event?.eventType === eventType && event?.eventAction === eventAction;
            }),
        );
    }

    public dispatch<T>(event: SseEvent<T>): void {
        this.eventDispatcher.next(event);
    }

    private isSupported() {
        if (typeof EventSource == 'undefined') {
            return false;
        }

        return true;
    }

    private setEventSource(sessionId: string): void {
        try {
            if (!this.isSupported()) {
                logInfo('SSE Service', 'SSE is not supported');

                return;
            }

            this.eventSource = new EventSource(this.getSubscriptionUrl(sessionId));

            this.eventSource.onmessage = (event) => {
                let hydratedEvent;

                if (event.data === ':\n\n') {
                    // Heartbeat, can be ignored.
                    logEvent('ServerSentEventService', 'Heartbeat received');

                    return;
                }

                if (typeof event.data === 'string') {
                    hydratedEvent = JSON.parse(event.data);
                } else {
                    hydratedEvent = event.data;
                }

                const { eventAction, eventType } = hydratedEvent;

                const matchedEvent = this.listenedEvents.find(
                    (x) =>
                        (x.eventAction === eventAction && x.eventType === eventType) ||
                        x.eventType === event.data?.reference?.eventType ||
                        x.eventAction === event.data?.reference?.eventAction,
                );

                logEvent(
                    'SSE Service Event Received',
                    `type: ${event.data.eventType}, action: ${event.data.eventAction}`,
                );

                if (matchedEvent) {
                    logInfo('SSE Service', 'Dispatching event');
                    this.zone.run(() => {
                        this.dispatch(hydratedEvent);
                    });
                }
            };

            this.eventSource.onopen = () => {
                if (this.eventSource.readyState === EventSource.OPEN) {
                    logInfo('SSE Service', 'Connection established');
                    this.retryFrequencySeconds = 1;
                }
            };

            this.eventSource.onerror = (event) => {
                this.closeServerSentEvent(sessionId);
                this.reconnect(sessionId);
            };

            // Exponential retry
            this.retryFrequencySeconds *= 2;

            // Cap at 10 seconds
            if (this.retryFrequencySeconds >= 10) {
                this.retryFrequencySeconds = 10;
            }
        } catch (e) {
            throw new Error(e.message ?? 'SSE Service - failed to close eventSource');
        }
    }

    private getSubscriptionUrl(sessionId: string): string {
        return `${this.url}/${sessionId}`;
    }

    private wait(): number {
        return this.retryFrequencySeconds * 1000;
    }

    private reconnect(sessionId: string) {
        try {
            if (!this.isSupported()) {
                logInfo('SSE Service', 'SSE is not supported');

                return;
            }

            if (this.eventSource.readyState !== EventSource.CONNECTING) {
                logInfo('SSE Service', `Reconnecting to SSE event stream in ${this.retryFrequencySeconds} seconds`);

                const retry = this.debounce(() => {
                    logInfo('SSE Service', 'Reconnecting to SSE event stream (now)');
                    this.setEventSource(sessionId);
                }, this.wait());

                retry();
            } else {
                logInfo('SSE Service', 'already reconnecting - request ignored');
            }
        } catch (e) {
            throw new Error(e.message ?? 'SSE Service - failed to close eventSource');
        }
    }

    private debounce(operation: Function, wait: number): Function {
        let timeout: any;

        return function () {
            const context = this;
            const args = arguments;

            const wrapper = function () {
                timeout = null;
                operation.apply(context, args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(wrapper, wait);
        };
    }
}
