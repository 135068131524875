import { BenefitStatus } from './insurance-benefit.model';

export type EstimateTags = {
    expectedBenefits?: boolean;
    successBenefits?: boolean;
    expectedAddressVerification?: boolean;
    successAddressVerification?: boolean;

    benefitStatus?: BenefitStatus;
    copayOverrideApplied?: boolean;
    payerIsUnmapped?: boolean;
    rteSuppressed?: boolean;
};

export const getPendingEstimateMessage = (tags: EstimateTags) => {
    let message = 'Estimate pending.';

    if (tags?.expectedBenefits && !tags?.expectedAddressVerification) {
        message += ' Awaiting eligibility.';
    }

    if (tags?.expectedAddressVerification && !tags?.expectedBenefits) {
        message += ' Awaiting address verification.';
    }

    if (tags?.expectedBenefits && tags?.expectedAddressVerification) {
        message += ' Awaiting eligibility and address verification.';
    }

    return message;
};
