export const environment = {
    production: true,
    environmentName: 'production',
    em3Api: 'https://em3.trueaccess.care',
    consumerApi: 'https://patient-api.api.trueaccess.care',
    workflowApi: 'https://rsf-workflow-api.api.trueaccess.care',
    eesApi: 'https://ees-api.api.trueaccess.care',
    eesApiWs: 'wss://ees-api.api.trueaccess.care',
    remotes: {
        workflow: 'https://rsf-portal-workflow.app.trueaccess.care/remoteEntry.js',
    },
};
