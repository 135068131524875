import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'wq-base',
    template: ``
})
export abstract class BaseComponent implements OnDestroy {

    protected subscriptionNotifier = new Subject();

    public ngOnDestroy() {
        this.subscriptionNotifier.next(true);
        this.subscriptionNotifier.complete();
    }
}
